import React from 'react';
import './style.sass';

export default props => {
  const { image } = props;
  let src = '';
  let srcSet = '';
  if (!!image && !!image.url) {
    src = image.url;
  } else {
    src = image.childImageSharp.fluid.src;
    srcSet = image.childImageSharp.fluid.srcSet;
  }

  return (
    <div className="headerVisual__container">
      <img
        className="headerVisual__background"
        src={src}
        alt=""
        srcSet={srcSet}
      />
      <img className="headerVisual__image" src={src} alt="" srcSet={srcSet} />
    </div>
  );
};
