import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import constants from '../constants';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Content, { HTMLContent } from '../components/Content';
import HeaderVisual from '../components/HeaderVisual';
import BlogList from '../components/BlogList';
import Chips from '../components/Chips';
import SubTitle, { SubTitleTypes } from '../components/SubTitle';
// import MainIcons from '../components/MainIcons';
import '../sass/pages/shrine-post.sass';

export const ShrinePostTemplate = ({
  content,
  contentComponent,
  name,
  description,
  blessings,
  title,
  address,
  map,
  postalcode,
  station,
  official,
  featuredimage,
  // businesshours,
  // tags,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <div className="shrinePost">
      {!!featuredimage ? <HeaderVisual image={featuredimage} /> : null}

      <div className="shrinePost__title">
        {title ? title.replace(/ *\[[^)]*\] */g, '') : ''}
      </div>

      {/* <MainIcons prayer={false} amulet={false} fortune={false} /> */}

      <div className="shrinePost__blessings">
        {!blessings ? null : (
          <Chips type={constants.articleTypes.blessing} values={blessings} />
        )}
      </div>

      <div className="shrinePost__subTitle">アクセス</div>

      <div className="shrinePost__accessInfo">
        <ul>
          <li>〒{postalcode}</li>
          <li>住所: {address}</li>
          <li>最寄り駅: {station}</li>
          <li>
            <a href={official} target="_blank" rel="noopener noreferrer">
              {name} 公式サイト
            </a>
          </li>
        </ul>
      </div>

      <div className="shrinePost__map">
        <iframe
          title="googleMap"
          src={map}
          className="companyTable__map"
          width="100%"
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          frameBorder="no"
        />
      </div>

      <div className="shrinePost__subTitle">神社概要</div>
      <div className="shrinePost__description">{description}</div>

      <PostContent content={content} />

      <SubTitle type={SubTitleTypes.article} />

      {!name ? null : (
        <BlogList type={constants.articleTypes.shrine} value={name} />
      )}
    </div>
  );
};

ShrinePostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  name: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  map: PropTypes.string,
  blessings: PropTypes.array,
  businesshours: PropTypes.string,
  postalcode: PropTypes.string,
  station: PropTypes.string,
  official: PropTypes.string,
  featuredimage: PropTypes.object,
  // tags: PropTypes.array,
};

const ShrinePost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <Seo
        title={`${post.frontmatter.title.replace(
          / *\[[^)]*\] */g,
          ''
        )} | ヒナタビ`}
        description={`${post.frontmatter.description}`}
        href={location.href}
      />
      <ShrinePostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        name={post.frontmatter.name}
        blessings={post.frontmatter.blessings}
        title={post.frontmatter.title}
        address={post.frontmatter.address}
        map={post.frontmatter.map}
        featuredimage={post.frontmatter.featuredimage}
        postalcode={post.frontmatter.postalcode}
        station={post.frontmatter.station}
        official={post.frontmatter.official}
        // businesshours={post.frontmatter.businesshours}
        // tags={post.frontmatter.tags}
      />
    </Layout>
  );
};

ShrinePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ShrinePost;

export const pageQuery = graphql`
  query ShrinePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        name
        title
        description
        blessings
        tags
        address
        map
        postalcode
        station
        official
        businesshours
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 120, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
