import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

export const HTMLContent = ({ content, className }) => (
  <article
    className={className}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <article className={className}>{content}</article>
);

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
